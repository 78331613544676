@keyframes blink {
    0% {
        opacity: .1;
    }
    20% {
        opacity: 1;
    }
    100% {
        opacity: .1;
    }
}

@keyframes pulseAnimation {
    0% {
        transform: scale(1);
    }
    50% {
        transform: scale(1.1);
    }
    100% {
        transform: scale(1);
    }
}

@keyframes advancedAnimation {
    0% {
        transform: rotate(0deg) scale(1);
    }
    50% {
        transform: rotate(180deg) scale(1.1);
    }
    100% {
        transform: rotate(360deg) scale(1);
    }
}

@keyframes ring {
    0% {
        width: 50px;
        height: 50px;
        opacity: 1;
    }
    100% {
        width: 200px;
        height: 200px;
        opacity: 0;
    }
}

@keyframes neonPulse {
    0%, 100% {
        box-shadow: 0 0 1px rgba(100, 171, 255, 0.8),
        0 0 20px rgba(68, 76, 198, 0.8),
        0 0 30px rgba(100, 171, 255, 0.8);
    }
    50% {
        box-shadow: 0 0 20px rgba(100, 171, 255, 1),
        0 0 30px rgba(68, 76, 198, 1),
        0 0 40px rgba(100, 171, 255, 1),
        0 0 50px rgba(68, 76, 198, 1);
    }
}

@keyframes pulse {
    0% {
        box-shadow: 0 0 0 0 rgba(68, 76, 198, 0.4);
    }
    70% {
        box-shadow: 0 0 0 10px rgba(68, 76, 198, 0);
    }
    100% {
        box-shadow: 0 0 0 0 rgba(68, 76, 198, 0);
    }
}

@keyframes popIn {
    0% {
        transform: scale(0.9);
        opacity: 0.5;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}

@keyframes spinAndWave {
    0%, 20% {
        transform: translate(-50%, -50%) rotate(0deg);
        top: 50%;
        left: 50%;
    }
    40% {
        transform: translate(-50%, -50%) rotate(360deg);
        top: 60%;
        left: 60%;
    }
    60% {
        transform: translate(-50%, -50%) rotate(720deg);
        top: 70%;
        left: 40%;
    }
    80% {
        transform: translate(-50%, -50%) rotate(1080deg);
        top: 80%;
        left: 60%;
    }
    100% {
        transform: translate(-50%, -50%) rotate(1440deg);
        top: 90%;
        left: 50%;
    }
}

.message-enter {
    animation: slideUp 0.8s ease forwards;

}

@keyframes slideUp {
    from {
        transform: translateY(50%); /* Было 100%, теперь 50% */
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes highlightButton {
    0%, 100% {
        transform: scale(1);
        box-shadow: 0px 0px 1px rgba(68, 76, 198, 0.8);
        filter: brightness(100%);
    }
    50% {
        transform: scale(1.1);
        box-shadow: 0px 0px 3px rgba(68, 76, 198, 0.5);
    }
}

@keyframes neonGlowAnimation {
    0%, 100% {
        box-shadow: 0 0 5px #FFF, 0 0 15px #64ABFF, 0 0 25px #64ABFF, 0 0 35px #444CC6;
        background: linear-gradient(45deg, #FFF, #64ABFF, #444CC6, #FFF);
        border-color: #64ABFF;
    }
    50% {
        box-shadow: 0 0 10px #FFF, 0 0 20px #64ABFF, 0 0 30px #64ABFF, 0 0 40px #444CC6;
        background: linear-gradient(45deg, #444CC6, #64ABFF, #FFF, #444CC6);
        border-color: #FFF;
    }
}

.myIconButton::after {
    content: '';
    width: 30px;
    height: 30px;
    border-radius: 100%;
    border: 2px solid #C59BFF;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ring 1.5s infinite;
}


/*@keyframes wave {*/
/*  0%, 60%, 100% {*/
/*    transform: translateY(0%);*/
/*  }*/
/*  30% {*/
/*    transform: translateY(-15%);*/
/*  }*/
/*}*/

/*.processing span {*/
/*  display: inline-block;*/
/*  animation: wave 1.5s infinite;*/
/*  !* Each letter animation is delayed by 100ms relative to the previous letter *!*/
/*  animation-delay: calc(var(--letter-index) * 0.1s);*/
/*}*/

/*.processing span:nth-child(1) { --letter-index: 0; }*/
/*.processing span:nth-child(2) { --letter-index: 1; }*/
/*.processing span:nth-child(3) { --letter-index: 2; }*/
/*.processing span:nth-child(4) { --letter-index: 3; }*/
/*.processing span:nth-child(5) { --letter-index: 4; }*/
/*.processing span:nth-child(6) { --letter-index: 5; }*/
/*.processing span:nth-child(7) { --letter-index: 6; }*/
/*.processing span:nth-child(8) { --letter-index: 7; }*/
/*.processing span:nth-child(9) { --letter-index: 8; }*/
/*.processing span:nth-child(10) { --letter-index: 9; }*/


@keyframes dots {
    0%, 20% {
        content: '.';
    }
    20%, 40% {
        content: '..';
    }
    40%, 60% {
        content: '...';
    }
    60%, 80% {
        content: '..';
    }
    80%, 100% {
        content: '.';
    }
}

.processing::after {
    content: '';
    animation: dots 2s infinite;
    display: inline-block;
    width: 1ch; /* reserve space for three dots */
    text-align: left;
}


#ResumeContainer {
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.PDFDocument {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.custom-mark {
    background-color: transparent;
    font-weight: normal;
}

.PDFPage {
    /*box-shadow: 0 0 8px rgba(0, 0, 0, .5);*/
}

.PDFPageOne {
    /*margin-bottom: 25px;*/
}

.PDFPage > canvas {
    max-width: 100%;
    height: auto !important;
}


.textLayer {
    z-index: 2;
    opacity: 1;
    mix-blend-mode: multiply;
    display: flex;
}

.annotationLayer {
    position: absolute;
    top: 0;

    z-index: 3;
}

html
body
.textLayer
> div:not(.PdfHighlighter__highlight-layer):not(.Highlight):not(.Highlight-emoji) {
    opacity: 1;
    mix-blend-mode: multiply;
}

.textLayer ::selection {
    background: rgba(252, 232, 151, 1);
    mix-blend-mode: multiply;
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textLayer {
        opacity: 0.5;
    }
}

/* Internet Explorer support method */
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textLayer {
        opacity: 0.5
    }
}

/* Microsoft Edge Browser 12+ (All) - @supports method */
@supports (-ms-ime-align:auto) {
    .textLayer {
        opacity: 0.5
    }
}


.Tip__compact {
    cursor: pointer;
    background-color: #3d464d;
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: white;
    padding: 5px 10px;
    border-radius: 3px;
}

.Tip__card {
    padding: 10px;
    background: #fff;
    background-clip: padding-box;
    border: 1px solid #e8e8e8;
    border-radius: 4px;
    box-shadow: 0 2px 4px rgba(37, 40, 43, 0.2);
}

.Tip__card textarea {
    font-size: 16px;
    width: 200px;
    height: 70px;
}

.Tip__card input[type="submit"] {
    margin-top: 5px;
    font-size: large;
}


.PdfHighlighter {
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.PdfHighlighter__highlight-layer {
    position: absolute;
    z-index: 3;
    left: 0;
}

.PdfHighlighter__tip-container {
    z-index: 6;
    position: absolute;
}

.PdfHighlighter--disable-selection {
    user-select: none;
    pointer-events: none;
}

.PdfHighlighter {
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.PdfHighlighter__highlight-layer {
    position: absolute;
    z-index: 3;
    left: 0;
}

.PdfHighlighter__tip-container {
    z-index: 6;
    position: absolute;
}

.PdfHighlighter--disable-selection {
    user-select: none;
    pointer-events: none;
}

.PdfHighlighter {
    position: absolute;
    overflow: auto;
    width: 100%;
    height: 100%;
}

.PdfHighlighter__highlight-layer {
    position: absolute;
    z-index: 3;
    left: 0;
}

.PdfHighlighter__tip-container {
    z-index: 6;
    position: absolute;
}

.PdfHighlighter--disable-selection {
    user-select: none;
    pointer-events: none;
}

.Highlight {
    position: absolute;
}

.Highlight__emoji {
    position: absolute;
    color: black;
    opacity: 1;
    font-size: 28px;
}

.Highlight__parts {
    opacity: 1;
}

.Highlight__part {
    cursor: pointer;
    position: absolute;
    background: rgba(255, 226, 143, 1);
    transition: background 0.3s;
}

.Highlight__popup {
    background-color: #3d464d;
    border: 1px solid rgba(255, 255, 255, 0.25);
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 3px;
    max-width: 300px;
    max-height: 100px;
    overflow-y: scroll;
}

.Highlight--scrolledTo .Highlight__part {
    background: #ff4141;
}


.AreaHighlight {
    border: 1px solid #333;
    background-color: rgba(252, 232, 151, 1.0);
    opacity: 1;
    mix-blend-mode: multiply;
}

.AreaHighlight__part {
    cursor: pointer;
    position: absolute;
    background: rgba(255, 226, 143, 1);
    transition: background 0.3s;
}

.AreaHighlight--scrolledTo .AreaHighlight__part {
    background: #ff4141;
}


@keyframes skeleton-strip {
    0% {
        background-position: -100%; /* Начало с левого края элемента */
    }
    100% {
        background-position: 100%; /* Перемещение фона до правого края элемента */
    }
}


@keyframes slide-in1 {
    from {
        opacity: 0;
        transform: translateX(-100px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}


@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.fadeInUp {
    animation: fadeInUp 0.8s ease-out;
}


@keyframes pulse1 {
    0% {
        transform: scale(1);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }
    50% {
        transform: scale(1.05);
        box-shadow: 0 6px 16px rgba(0, 0, 0, 0.3);
    }
    100% {
        transform: scale(1);
        box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
    }
}
